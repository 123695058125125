<template>
  <section class="road-map" id="Roadmap">
    <div class="container">
      <div class="title-wrap">
        <span
          v-if="roadMapData.secSubTitle1"
          class="sub-title aos-init aos-animate"
          data-aos="fade-up"
          data-aos-duration="700"
          >{{ roadMapData.secSubTitle1 }}</span
        >
        <div>
          <h2
            v-if="roadMapData.secTitle1"
            class="getItNow-title aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            {{ roadMapData.secTitle1 }}
          </h2>
          <h2
            v-if="roadMapData.secTitle2"
            class="getItNow-title pb aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="900"
          >
            {{ roadMapData.secTitle2 }}
          </h2>
        </div>
        <span
          v-if="roadMapData.secSubTitle2"
          class="sub-title aos-init aos-animate"
          data-aos="fade-up"
          data-aos-duration="1000"
          >{{ roadMapData.secSubTitle2 }}</span
        >
      </div>
      <div
        v-if="roadMapData.coinfellaImg.src"
        class="rd-mp-coinfella"
      >
        <img
          :src="roadMapData.coinfellaImg.src"
          :alt="roadMapData.coinfellaImg.alt"
        />
      </div>
    </div>

    <!-- Start Slider -->
    <road-map-slider :roadMapData="roadMapData" />
    <!-- End Slider -->
  </section>
</template>
      
<script>
import RoadMapSlider from "../roadMapSlider.vue";

export default {
  name: "RoadMapSection",
  components: { RoadMapSlider },
  props: ["roadMapData"],
  data: () => {
    return {};
  },
};
</script>
      
  <style scoped></style>
      