<template>
    <router-view />
</template>

<script>
import AOS from "aos";
export default {
    mounted() {
        AOS.init({
            offset: 80
        });
    }
}
</script>
<style></style>
