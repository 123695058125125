<template>
  <section class="faq-area" id="Faq">
    <h2
      class="getItNow-title aos-init aos-animate"
      data-aos="fade-up"
      data-aos-duration="800"
    >
      {{ faqData.secTitle }}
    </h2>
    <ul class="faq-wrap">
      <faq-list v-for="(faq, i) in faqData.faqs" :faq="faq" :key="i" />
    </ul>
  </section>
</template>
            
<script>
import FaqList from '../faqList.vue';
export default {
  name: "FaqSection",
  components: {
    FaqList
  },
  props: ["faqData"],
  data: () => {
    return {};
  },
  setup() {
    return {};
  },
};
</script>
            
<style scoped></style>
            