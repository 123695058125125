<template>
  <header class="header_area" :class="isActiveMenu ? 'active' : ''">
    <div class="container">
      <div class="header_wrap">
        <a href="#hero" class="logo">$BEAST</a>
        
        <!-- Start Nav -->
        <div class="navArea">
          <div class="social-link-wrap">
            <a
              v-for="(socialLink, i) in templateHeader.socialLinks"
              :key="i"
              :href="socialLink.link"
              class="social-link"
              target="_blank"
            >
              <img
                v-if="socialLink.icon"
                :src="socialLink.icon"
                :alt="socialLink.type"
              />
            </a>
          </div>
          
          <!-- End Social -->
          <div class="cta-wrap">
            <a
              href="https://t.me/MRBEASTSOLCOIN"
              class="primary-btn purchase-btn"
            >
              <span>Buy</span>
            </a>
            <!-- Menu Show/Close Button -->
            <button class="menu-btn" @click="isActiveMenu = !isActiveMenu">
              <span class="bars-wrap">
                <span class="bar"></span>
                <span class="bar"></span>
                <span class="bar"></span>
              </span>
            </button>
          </div>
        </div>
        <!-- End Nav -->
      </div>
      <nav class="nav-menu-wrap">
        <div class="nav-links-wrap">
          <a
            v-for="(navContent, i) in templateHeader.navLinksContents"
            :key="i"
            :href="navContent.path"
            class="nav-link"
            @click="isActiveMenu = false"
            ><span>{{ navContent.title }}</span></a
          >
        </div>
        <img :src="templateHeader.navImg.src" class="pouchfella" :alt="templateHeader.navImg.alt" />
        <div class="social-link-wrap two">
          <a
            v-for="(socialLink, i) in templateHeader.socialLinks"
            :key="i"
            :href="socialLink.link"
            class="social-link"
            target="_blank"
          >
            <img
              v-if="socialLink.icon2"
              :src="socialLink.icon2"
              :alt="socialLink.type"
            />
          </a>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "HelloWorld",
  props: ["templateHeader"],
  data() {
    return {
      isActiveMenu: false
    }
  }
};
</script>

<style scoped></style>
