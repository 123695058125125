<template>
	<li
			class="faq"
			:class="{active: isActive, [faq.cus_class]: true}"
			@click="accordionHandler"
	>
			<div
					v-if="faq.title || faq.content || faq.icon"
					class="faq-content-wrap"
			>
					<img v-if="faq.icon" :src="faq.icon" alt="Icon" />
					<div class="q-and-a-wrap">
							<h6 v-if="faq.title" class="faq-q">
									{{ faq.title }}
							</h6>
							<p v-if="faq.content" class="faq-a">
									{{ faq.content }}
							</p>
					</div>
			</div>
	</li>
</template>
<script>
export default {
    name: "faqList",
		props: ['faq'],
		data() {
			return {
				isActive: false
			}
		},
		methods: {
			accordionHandler() {
				if (this.isActive) {
					this.isActive = false;
				} else {
					this.isActive = true;
				}
			}
		}
};
</script>