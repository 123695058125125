<template>
  <section class="hero-area" id="hero">
    <div v-if="heroData.heroBgPattern.src" class="hero-bg-pattern">
      <div class="pattern-box">
        <img
          :src="heroData.heroBgPattern.src"
          class="hero-pattern"
          :alt="heroData.heroBgPattern.alt"
        />
      </div>
    </div>
    <!-- End Hero Pattern -->
    <div class="hero-contents">
      <div class="hero-contents-wrap">
        <h6
          v-if="heroData.subTitle"
          class="sub-title aos-init aos-animate"
          data-aos="fade-up"
          data-aos-duration="400"
        >
          {{ heroData.subTitle }}
        </h6>
        <div class="headlines">
          <h1
            v-if="heroData.title"
            class="hero-title aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="600"
          >
            {{ heroData.title }}
          </h1>
          <p
            v-if="heroData.description"
            class="describtion aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="700"
          >
            {{ heroData.description }}
          </p>
          <div
            class="cta-wrap aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="800"
          >
            <a
              href="https://t.me/MRBEASTSOLCOIN"
              target="_blank"
              class="primary-btn"
              ><span>Buy $BEAST</span></a
            >
            <a href="#" class="secondery-btn">learn More</a>
          </div>
        </div>
      </div>
    </div>

    <!-- Start Sub-Hero -->
    <div class="sub-hero-area">
      <div class="gtItNw-img-wrap">
        <div class="img-box">
          <img
            v-if="heroData.subHeroData.gtItNwImg1.src"
            :src="heroData.subHeroData.gtItNwImg1.src"
            :alt="heroData.subHeroData.gtItNwImg1.alt"
          />
          <img
            v-if="heroData.subHeroData.gtItNwImg2.src"
            :src="heroData.subHeroData.gtItNwImg2.src"
            :alt="heroData.subHeroData.gtItNwImg2.alt"
            class="caption"
          />
        </div>
      </div>
      <div class="address">
        <div class="address-wrap">
          <img
            v-if="heroData.subHeroData.cpyAdressImage"
            :src="heroData.subHeroData.cpyAdressImage"
            alt="Copy Icon"
          />
          <span class="cpy-status">{{ copiedText ? 'Copied!: ' : 'Token address: ' }}</span>
          <input
            type="text"
            id="cpy-text"
            ref="copyText"
            value="Coming Soon"
          />
          <button id="cpyBtn" @click="copy"></button>
        </div>
      </div>
      <!-- End Address -->
      <div class="getItNow-wrap">
        <div class="title-wrap">
          <h2
            v-if="
              heroData.subHeroData.gtItNwImg3.src ||
              heroData.subHeroData.gtItNwTitle
            "
            class="getItNow-title"
          >
            {{ heroData.subHeroData.gtItNwTitle }}
            <img
              v-if="heroData.subHeroData.gtItNwImg3.src"
              :src="heroData.subHeroData.gtItNwImg3.src"
              :alt="heroData.subHeroData.gtItNwImg3.alt"
            />
          </h2>
          <h2 v-if="heroData.subHeroData.gtItNwTitle2" class="getItNow-title">
            {{ heroData.subHeroData.gtItNwTitle2 }}
          </h2>
        </div>
        <div class="gtItNw-area">
          <div class="gtItNw-links-wrap">
            <a
              v-for="(gtItNwLink, index) in heroData.subHeroData.gtItNwLinks"
              :key="index"
              :href="gtItNwLink.path_url"
              :class="gtItNwLink.cus_class"
              :target="gtItNwLink.target"
              >{{ gtItNwLink.title }}</a
            >
          </div>
        </div>
      </div>
      <!-- End Get It Now -->
    </div>
    <!-- End Sub-Hero -->
  </section>
</template>
  
  <script>
export default {
  name: "HeroArea",
  components: {},
  props: ["heroData"],
  data: () => {
    return {
      copiedText: false
    };
  },
  methods: {
    async copy() {
    try {
      const copyText = this.$refs.copyText;
      copyText.select();
      await navigator.clipboard.writeText(copyText.value);
      this.copiedText = true;
      setTimeout(() => {
        this.copiedText = false;
      }, 1000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  }
  }
};
</script>
  
  <style scoped></style>
  