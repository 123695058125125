<template>
  <main id="page-main">
    <!-- Start Header -->
    <template-header :templateHeader="templateHeader" />
    <!-- End Header -->

    <!-- Start Hero -->
    <hero-area :heroData="heroData" />
    <!-- End Hero -->

    <!-- Start About -->
    <about-area :aboutData="aboutData" />
    <!-- End About -->

    <!-- Start Features -->
    <features-area :featureData="featureData" />
    <!-- End Features -->

    <!-- Start Tokenoimcs -->
    <tokenomics-area :tokenomicsData="tokenomicsData" />
    <!-- End Tokenoimcs -->

    <!-- Start Road Map -->
    <road-map-area :roadMapData="roadMapData" />
    <!-- End Road Map -->

    <!-- Start How To Buy -->
    <how-to-buy-area :howToBuyData="howToBuyData" />
    <!-- End How To Buy -->

    <!-- Start Faq -->
    <faq-area :faqData="faqData" />
    <!-- End Faq -->

    <!-- Start Footer -->
    <footer-area :footerData="footerData" />
    <!-- End Footer -->
  </main>
</template>

<script>
import templateHeader from "@/components/sections/templateHeader.vue";
import heroArea from "@/components/sections/heroSection.vue";
import aboutArea from "@/components/sections/aboutSection.vue";
import featuresArea from "@/components/sections/featuresSection.vue";
import tokenomicsArea from "@/components/sections/tokenomicsSection.vue";
import roadMapArea from "@/components/sections/roadMapSection.vue";
import howToBuyArea from "@/components/sections/howToBuySection.vue";
import faqArea from "@/components/sections/faqSection.vue";
import footerArea from "@/components/sections/footerSection.vue";

export default {
  name: "HomeView",
  components: {
    templateHeader,
    heroArea,
    aboutArea,
    featuresArea,
    tokenomicsArea,
    roadMapArea,
    howToBuyArea,
    faqArea,
    footerArea,
  },

  data: () => {
    return {
      // #Header Contents
      templateHeader: {
        navImg: {
          src: require("@/assets/img/pepe.png"),
          alt: "pouchfella",
        },
        socialLinks: [
          {
            type: "Telegram",
            link: "https://t.me/MRBEASTSOLCOIN",
            icon: require("@/assets/img/telegram.png"),
            icon2: require("@/assets/img/telegram-2.png"),
          },

          {
            type: "Twitter",
            link: "https://x.com/MRBEASTSOLCOIN",
            icon: require("@/assets/img/twitter.png"),
            icon2: require("@/assets/img/twitter.png"),
          },

          {
            type: "Instagram",
            link: "https://www.instagram.com/mrbeastsolcoin?igsh=MXB0ajIzdG1ueTFmNg%3D%3D&utm_source=qr",
            icon: require("@/assets/img/insta1.png"),
            icon2: require("@/assets/img/insta1.png"),
          },
        ],

        navLinksContents: [
          {
            title: "About",
            path: "#about",
          },

          {
            title: "Features",
            path: "#Features",
          },

          {
            title: "Tokenomics",
            path: "#Tokenomics",
          },

          {
            title: "Roadmap",
            path: "#Roadmap",
          },

          {
            title: "How To Buy",
            path: "#HowToBuy",
          },

          {
            title: "Faq",
            path: "#Faq",
          },
        ],
      },

      // #Hero Contents
      heroData: {
        subTitle: "Introducing",
        title: "mr.beast coin",
        description:
          "$BEAST – Hold tight. Last to hold wins big!",
        heroBgPattern: {
          src: require("@/assets/img/shape.png"),
          alt: "hero-pattern",
        },
        subHeroData: {
          gtItNwImg1: {
            src: require("@/assets/img/Beastlogo3.png"),
            alt: "Illustration",
          },
          gtItNwImg2: {
            src: require("@/assets/img/pixle.gif"),
            alt: "Caption",
          },
          gtItNwImg3: {
            src: require("@/assets/img/shape-2.png"),
            alt: "fsdfsdfs",
          },
          cpyAdressImage: require("@/assets/img/copy.png"),
          gtItNwTitle: "Coming",
          gtItNwTitle2: "Soon on:",
          cpyPath: "Coming Soon",
          gtItNwLinks: [
            {
              title: "Gate.io",
              path_url: "https://www.gate.io/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "Bitget",
              path_url: "https://www.bitget.com/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "BingX",
              path_url: "https://www.bingx.com/en-us/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "LBANK",
              path_url: "https://www.lbank.com/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "Gate.io",
              path_url: "https://www.gate.io/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "Bitget",
              path_url: "https://www.bitget.com/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "BingX",
              path_url: "https://www.bingx.com/en-us/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "LBANK",
              path_url: "https://www.lbank.com/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "Gate.io",
              path_url: "https://www.gate.io/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "Bitget",
              path_url: "https://www.bitget.com/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "BingX",
              path_url: "https://www.bingx.com/en-us/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
            {
              title: "LBANK",
              path_url: "https://www.lbank.com/",
              cus_class: "gtItNw-link",
              target: "_blank",
            },
          ],
        },
      },

      // #About Contents
      aboutData: {
        secTitle: "What is $BEAST about?",
        stryHeadlineTitle: "Nothing is Impossible with mr.beast coin",
        stryRocketIllustration: require("@/assets/img/Beastmoon.png"),
        stryDescriptions: "<p>Mr. Beast Coin ($BEAST): A fun and exciting coin inspired by Mr. Beast’s bold and generous spirit. Community-Driven: Made by fans and developers who love viral challenges, huge giveaways, and doing the impossible in the crypto world. Our Mission: To help people create viral moments, make a difference worldwide, and spread kindness using digital money. Platform Goals: Host huge charity events, create life-changing chances, and do the impossible. Community Focused: Building a group where we change what’s possible in cryptocurrency together. Join the Movement: Be part of something amazing where generosity and boldness create something truly special with Mr. Beast Coin ($BEAST).</p>"
      },

      // #Features Contents
      featureData: {
        secSubTitle: "We got a really",
        secTitle: {
          txt1: "Strong",
          txt2: "Foundation",
        },
        featureLists: [
          {
            img: require("@/assets/img/24.png"),
            title: "Strategic <br/> Partnerships",
            description:
              "$BEAST works with big names in crypto and popular meme communities to help us all reach new heights together.",
          },
          {
            img: require("@/assets/img/32.png"),
            title: "Community <br/> Support",
            description:
              "$BEAST is powered by its awesome community! We stay connected, work together, and make sure everyone has a chance to win.",
          },
          {
            img: require("@/assets/img/25.png"),
            title: "Growing <br/> Ecosystem",
            description:
              "As more people join $BEAST—like users, developers, and investors—we’re creating even more ways to team up, help each other, and grow together.",
          },
        ],
      },

      // #Tokenomics Contents
      tokenomicsData: {
        secSubTitle: "It's always about that",
        secTitle: "Tokenomics",
        bigImage: require("@/assets/img/Beastheli.png"),
        shape: require("@/assets/img/shape-4.png"),
        tokenomicsLinks: [
          {
            title: "Tokenomics",
          },
          {
            title: "Tokenomics",
          },
          {
            title: "Tokenomics",
          },
          {
            title: "Tokenomics",
          },
          {
            title: "Tokenomics",
          },
          {
            title: "Tokenomics",
          },
          {
            title: "Tokenomics",
          },
          {
            title: "Tokenomics",
          },
        ],
        chartLegends: [
          {
            chartLegend: "Cauasian",
          },
          {
            chartLegend: "Hispanic",
          },
          {
            chartLegend: "African",
          },
          {
            chartLegend: "Asian",
          },
        ],
        chartOptions: {
          data: [70, 10, 10, 10],
          labels: ["70% ", "10%", "10%", "10%"],
          backgroundColor: [
            "rgba(121, 204, 158, 1)",
            "rgba(255, 135, 23, 1)",
            "rgba(226, 254, 165, 1)",
            "rgba(248, 255, 232, 1)"
          ],
          type: 'doughnut',
          tooltip: true
        }
      },

      // #RoadMap Contents
      roadMapData: {
        secTitle1: "Road",
        secTitle2: "Map",
        secSubTitle1: "We are on the",
        secSubTitle2: "to heaven.",
        nextBtnImg: {
          src: require("@/assets/img/next-btn.png"),
          alt: "nextBtn",
        },
        prevBtnImg: {
          src: require("@/assets/img/prev-btn.png"),
          alt: "prevBtn",
        },
        coinfellaImg: {
          src: require("@/assets/img/Presbeast.png"),
          alt: "Coinfella",
        },
        roadMapSliders: [
          {
            title: "Stage 1:",
            subTitle: `"Conceptualization"`,
            cus_class: "orange",
            eventTypes: [
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "Research",
              },
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "Team Building",
              },
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "Whitepaper",
              },
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "Compliance",
              },
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "Community",
              },
            ],
          },

          {
            title: "Stage 2:",
            subTitle: `"Development"`,
            cus_class: "cyan",
            eventTypes: [
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "Blockchain",
              },
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "wallets",
              },
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "Website",
              },
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "Testing",
              },
              {
                checkMark: {
                  src: require("@/assets/img/event-present.svg"),
                  alt: "Event",
                },
                eventType: "Listings",
              },
            ],
          },

          {
            title: "Stage 3:",
            subTitle: `"Launch"`,
            cus_class: "majestic-purple",
            eventTypes: [
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Token",
              },
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Marketing",
              },
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Use Cases",
              },
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Education",
              },
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Feedback",
              },
            ],
          },

          {
            title: "Stage 4:",
            subTitle: `"Growth"`,
            cus_class: "deep-moss-green",
            eventTypes: [
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Governance",
              },
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Incentives",
              },
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Partnerships",
              },
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Scaling",
              },
              {
                checkMark: {
                  src2: require("@/assets/img/event-not-present.svg"),
                  alt: "Event",
                },
                eventType: "Vision",
              },
            ],
          },
        ],
      },

      // #How_To_Buy Contents
      howToBuyData: {
        secTitle: "How to buy?",
        promoMessage: `Ready for the next big challenge? Awesome! Just hit the button below and start stacking up your Mr. Beast Coins ($BEAST) right now.`,
        img1: require("@/assets/img/tablebeast.png"),
        img2: require("@/assets/img/Stoppoor.png")
      },

      // #Faq Contents
      faqData: {
        secTitle: "Got Some Faq?",
        faqs: [
          {
            title: "What is Mr. Beast Coin ($BEAST)?",
            content: "$BEAST is a cryptocurrency inspired by Mr. Beast's epic challenges and generosity, focusing on creating viral moments, building legacies, and spreading kindness.",
            cus_class: "",
            icon: require("@/assets/img/plus.svg")
          },

          {
            title: "How can I buy Mr. Beast Coin?",
            content: "Purchase $BEAST on popular cryptocurrency exchanges by creating an account, depositing funds, and searching for $BEAST.",
            cus_class: "",
            icon: require("@/assets/img/plus.svg")
          },

          {
            title: "What makes Mr. Beast Coin unique?",
            content: "$BEAST combines the excitement of crypto with Mr. Beast’s legendary challenges, encouraging community involvement and generosity.",
            cus_class: "",
            icon: require("@/assets/img/plus.svg")
          },

          {
            title: "How do I participate in the $BEAST challenges?",
            content: "Follow our social media and website for challenge announcements, which may involve holding $BEAST, completing tasks, or contributing to goals.",
            cus_class: "",
            icon: require("@/assets/img/plus.svg")
          },

          {
            title: "Is Mr. Beast Coin safe to invest in? ",
            content: "$BEAST is built on secure blockchain technology, but like all cryptocurrencies, it carries risks. Invest wisely and do your research.",
            cus_class: "",
            icon: require("@/assets/img/plus.svg")
          },

          {
            title: "How can I get involved in the Mr. Beast Coin community?",
            content: "Join the $BEAST community on social media, forums, and by contributing to community-driven initiatives.",
            cus_class: "",
            icon: require("@/assets/img/plus.svg")
          },
        ],
      },

      // #Footer Contents
      footerData: {
        secTitle: "beastcoin",
        CTRPBtnTitle: "→ Cryptocurrency Trading Risk Policy",
        footerImg: require("@/assets/img/bean.png"),
        footerLinks: [
          {
            title: "About",
            path: "#about",
            cus_class: "",
          },

          {
            title: "Features",
            path: "#Features",
            cus_class: "",
          },

          {
            title: "Tokenomics",
            path: "#Tokenomics",
            cus_class: "",
          },

          {
            title: "Roadmap",
            path: "#Roadmap",
            cus_class: "",
          },

          {
            title: "How To Buy",
            path: "#HowToBuy",
            cus_class: "",
          },

          {
            title: "FAQ",
            path: "#Faq",
            cus_class: "",
          },
        ],
        policyDetails: {
          policy_title: "Cryptocurrency Trading Risk Policy:",
          policyLists: [
            {
              title: "Introduction:",
              description: "Before engaging in trading or holding cryptocurrencies through MrBeastSolCoin.com, it is crucial to understand the risks associated with digital currencies and blockchain technology. This document outlines the key risks that you should consider. We strongly advise you to read this policy carefully and consult with professional advisors before making any investment decisions.",
              cus_class: "",
            },

            {
              title: "1. Investment Risks",
              description: "Cryptocurrencies carry a high level of risk. The value of cryptocurrencies is extremely volatile and may fluctuate significantly within very short periods of time. Such volatility can result from changes in market dynamics, regulatory actions, technological advancements, or other factors. There is a substantial risk that you could lose all of your investment. You should only invest funds that you can afford to lose entirely.",
              cus_class: "",
            },

            {
              title: "2. Regulatory Uncertainty",
              description: "The legal and regulatory environment surrounding cryptocurrencies is still developing and is subject to significant uncertainty. Future changes in laws and regulations could materially impact the cryptocurrency in terms of its use, exchange, and value. It is your responsibility to understand the regulatory requirements that apply to you and to comply with them.",
              cus_class: "",
            },

            {
              title: "3. No Legal Protection",
              description: "Transactions in cryptocurrencies may not be reversible, and, therefore, losses due to fraudulent or accidental transactions may not be recoverable. Unlike traditional banks or credit card providers, there are generally no protections that can reverse a transaction if an error or fraud occurs.",
              cus_class: "",
            },

            {
              title: "4. Market Liquidity",
              description: "Liquidity in cryptocurrency markets can vary widely and lack of liquidity can lead to high volatility in prices. This could result in significant changes in the value of your cryptocurrency holdings and may make it difficult for you to sell your holdings at a reasonable price.",
              cus_class: "",
            },

            {
              title: "5. Technology Risks",
              description: "The technology underlying cryptocurrencies is blockchain, which relies on the internet and advanced computer hardware and software. As such, it is susceptible to a range of disruptions, including operational failures, cyber attacks, or significant changes in technology. Such disruptions can lead to losses.",
              cus_class: "",
            },

            {
              title: "6. No Guarantee of Returns",
              description: "There is no guarantee that cryptocurrency will increase in value or that it will not decrease. Past performance is not indicative of future results. You should carefully consider whether trading or holding cryptocurrency aligns with your financial goals and risk tolerance.",
              cus_class: "",
            },

            {
              title: "7. Tax Liability",
              description: "There is no guarantee that cryptocurrency will increase in value or that it will not decrease. Past performance is not indicative of future results. You should carefully consider whether trading or holding cryptocurrency aligns with your financial goals and risk tolerance.",
              cus_class: "",
            },

            {
              title: "8. Expert Advice Recommended",
              description: "Trading cryptocurrencies involves significant risk. We recommend that you consult with financial, legal, and tax advisors to better understand the risks associated with cryptocurrencies.",
              cus_class: "",
            },

            {
              title: "9. KYC/AML Compliance",
              description: "You are required to comply with all applicable anti-money laundering (AML) and know your customer (KYC) regulations. Failure to do so may result in the suspension or termination of your account.",
              cus_class: "",
            },

            {
              title: "10. Use at Your Own Risk",
              description: "You acknowledge that you are using MrBeastSolCoin.com's services at your own risk, understanding the volatile nature of the cryptocurrency market.",
              cus_class: "",
            },

            {
              title: "Conclusino:",
              description: "By using MrBeastSolCoin.com, you acknowledge that you have read, understood, and agreed to the terms of this Cryptocurrency Trading Risk Disclosure Policy. If you have any questions or require further clarification, please contact us directly before using our services.",
              cus_class: "",
            },
          ],
        },
      },
    };
  }
};
</script>

<style scoped></style>
