<template>
    <section class="about-area" id="about">
        <h2
            v-if="aboutData.secTitle"
            class="sec-title aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration="800"
        >
            {{ aboutData.secTitle }}
        </h2>
        <div class="story-wrap">
            <div class="story-headline">
                <div class="stry-hdlin-title-wrap">
                    <h4
                        v-if="aboutData.stryHeadlineTitle"
                        class="stry-hdlin-title"
                    >
                        {{ aboutData.stryHeadlineTitle }}
                    </h4>
                    <img
                        :src="aboutData.stryRocketIllustration"
                        class="stry-illustration"
                        alt="Illustration"
                    />
                </div>
            </div>
            <!-- End Headline -->
            <div class="stry-description-video-wrap">
                <div class="stry-description-wrap">
                    <div
                        class="stry-description"
                        v-html="aboutData.stryDescriptions"
                    ></div>
                    <a
                        href="https://t.me/MRBEASTSOLCOIN"
                        class="primary-btn"
                        ><span>Buy $BEAST</span></a
                    >
                </div>
                <div class="stry-video-wrap">
                    <div class="stry-video">
                        <iframe
                            src="https://player.vimeo.com/video/345945892?muted=1&amp;autoplay=1&amp;autopause=0&amp;controls=0&amp;loop=1&amp;app_id=122963"
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                            title="ISOMETRIC ILLUSTRATION"
                            data-ready="true"
                        ></iframe>
                        <button
                            class="vid-ply-btn"
                            @click="videoPopoverHandler"
                        >
                            <div class="vid-ply-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                >
                                    <path
                                        d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"
                                    ></path>
                                </svg>
                            </div>
                            <span>Watch the video</span>
                        </button>
                    </div>
                </div>
                <a
                    href="https://t.me/MRBEASTSOLCOIN"
                    class="primary-btn"
                    ><span>Buy $BEAST</span></a
                >
            </div>
        </div>

        <!-- Video Popover -->
        <div class="full-scrn-vid-wrap" :class="{ active: showVideoPopover }">
            <div class="full-scrn-vid">
                <iframe
                    src="https://player.vimeo.com/video/345945892?autoplay=1&amp;muted=1&amp;loop=1&amp;autopause=0&amp;controls=1"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen=""
                ></iframe>
                <button class="full-scrn-ext-btn">
                    <svg
                        class="checkmark"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 52 52"
                        @click="showVideoPopover = false"
                    >
                        <circle
                            class="checkmark_circle"
                            cx="26"
                            cy="26"
                            r="25"
                            fill="none"
                        ></circle>
                        <path
                            class="checkmark_check"
                            fill="none"
                            d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8"
                        ></path>
                        <path
                            class="checkmark_check2"
                            fill="none"
                            d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8"
                        ></path>
                    </svg>
                </button>
            </div>
        </div>
    </section>
</template>
  
<script>
export default {
    name: "AboutSection",
    components: {},
    props: ["aboutData"],
    data: () => {
        return {
          showVideoPopover: false
        };
    },
    methods: {
        videoPopoverHandler() {
          this.showVideoPopover = true;
        },
    },
};
</script>
  
<style scoped></style>
  