import { createApp } from 'vue';
import App from './App.vue';
import router from './router/routes.js';
import $ from 'jquery';

//#All Stylesheet
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "@/assets/css/main.css";
import "@/assets/css/responsive.css";

createApp(App).use(router).mount('#app');

window.$ = $;
window.jQuery = $;