<template>
  <div class="container">
    <div
      class="swiper swiper-initialized swiper-horizontal swiper-backface-hidden"
    >
      <swiper
        :modules="modules"
        :slidesPerView="3"
        :spaceBetween="32"
        :center-insufficient-slides="true"
        :breakpoints="{
          1200: {
            slidesPerView: 3,
            spaceBetween: 32,
          },
          810: {
            slidesPerView: 2,
          },
          310: {
            slidesPerView: 1,
          },
        }"
        :loop="true"
        :pagination="{
          clickable: true,
        }"
        :navigation="{
          prevEl: '.prev-btn',
          nextEl: '.next-btn'
        }"
        class="mySwiper swiper-wrapper"
        id="swiper-wrapper"
      >
        <swiper-slide
          v-for="(
            roadMapSlider, i
          ) in roadMapData.roadMapSliders"
          :key="i"
          class="swiper-slide swiper-slide-active"
          role="group"
          aria-label="1 / 4"
          data-swiper-slide-index="0"
          style="width: 378.667px; margin-right: 32px"
        >
          <div class="rd-mp-content">
            <div class="stage-wrap">
              <div class="title-wrap">
                <h4
                  v-if="roadMapSlider.title"
                  class="st-title"
                  :class="roadMapSlider.cus_class"
                >
                  {{ roadMapSlider.title }}
                </h4>
                <span
                  v-if="roadMapSlider.subTitle"
                  class="st-sub-title"
                  >{{ roadMapSlider.subTitle }}</span
                >
              </div>
              <ul class="events-wrap">
                <li
                  v-for="(
                    eventType_Content, index
                  ) in roadMapSlider.eventTypes"
                  :key="index"
                  class="rd-mp-event"
                >
                  <img
                    v-if="eventType_Content.checkMark.src"
                    :src="eventType_Content.checkMark.src"
                    :alt="eventType_Content.checkMark.alt"
                  />
                  <img
                    v-if="eventType_Content.checkMark.src2"
                    :src="eventType_Content.checkMark.src2"
                    :alt="eventType_Content.checkMark.alt"
                  />
                  <span v-if="eventType_Content.eventType">
                    {{ eventType_Content.eventType }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <span
        class="swiper-notification"
        aria-live="assertive"
        aria-atomic="true"
      ></span>
    </div>

    <div class="prev-next-btn-wrap">
      <button
        v-if="roadMapData.prevBtnImg.src"
        class="prev-btn"
        aria-label="Previous slide"
        aria-controls="swiper-wrapper-a71ab6101000dd1172"
      >
        <img
          :src="roadMapData.prevBtnImg.src"
          :alt="roadMapData.prevBtnImg.alt"
        />
      </button>
      <button
        v-if="roadMapData.nextBtnImg.src"
        class="next-btn"
        aria-label="Next slide"
        aria-controls="swiper-wrapper-a71ab6101000dd1172"
      >
        <img
          :src="roadMapData.nextBtnImg.src"
          :alt="roadMapData.nextBtnImg.alt"
        />
      </button>
    </div>
  </div>
</template>
      
      <script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

export default {
  name: "RoadMapSlider",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: ["roadMapData"],
  data: () => {
    return {};
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
};
</script>
      
  <style scoped></style>
      