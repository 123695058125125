<template>
    <section class="tokenomics-area" id="Tokenomics">
        <div class="title-wrap">
            <h6
                v-if="tokenomicsData.secSubTitle"
                class="sub-title aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="700"
            >
                {{ tokenomicsData.secSubTitle }}
            </h6>
            <h2
                v-if="tokenomicsData.secTitle"
                class="getItNow-title aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration="800"
            >
                {{ tokenomicsData.secTitle }}
            </h2>
        </div>
        <!-- End Title -->
        <div v-if="tokenomicsData.bigImage" class="pikissuperstar">
            <div class="pikissuperstar-inner">
                <img
                    data-jarallax-element="-100 0"
                    :src="tokenomicsData.bigImage"
                    alt="pikissuperstar"
                />
            </div>
        </div>

        <img
            v-if="tokenomicsData.shape"
            :src="tokenomicsData.shape"
            class="lv-shape"
            alt="Shape"
        />

        <div class="chart-wrap">
            <ul class="gtItNw-links-wrap tokenomics-links-wrap">
                <li
                    v-for="(
                        tokenomics_links_content, i
                    ) in tokenomicsData.tokenomicsLinks"
                    :key="i"
                    class="gtItNw-link tokenomic-link"
                >
                    <span v-if="tokenomics_links_content.title">{{
                        tokenomics_links_content.title
                    }}</span>
                </li>
            </ul>
            <div class="chart-container">
                <canvas
                    id="myPieChart"
                    style="
                        display: block;
                        box-sizing: border-box;
                        height: 450px;
                        width: 500px;
                    "
                    width="500"
                    height="450"
                ></canvas>
            </div>
            <div class="chart-lengends-wrap">
                <div
                    v-for="(chartLegend, i) in tokenomicsData.chartLegends"
                    :key="i"
                    class="chrt-legend"
                >
                    <span v-if="chartLegend.chartLegend">{{
                        chartLegend.chartLegend
                    }}</span>
                </div>
            </div>
        </div>
    </section>
</template>
    
<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
    name: "TokenomicsSection ",
    components: {},
    props: ["tokenomicsData"],
    data: () => {
        return {};
    },
    methods: {
        chartCanvas() {
            //# Chart Control
            Chart.register(ChartDataLabels);
            const myPieChart = document.querySelector("#myPieChart");
            if (myPieChart) {
                const ctx = myPieChart.getContext("2d");
                const data = {
                    labels: this.tokenomicsData.chartOptions.labels,
                    datasets: [
                        {
                            data: this.tokenomicsData.chartOptions.data,
                            backgroundColor: this.tokenomicsData.chartOptions.backgroundColor,
                            borderColor: "rgba(0, 0, 0, 1)",
                            borderWidth: 4,
                        },
                    ],
                };

                const options = {
                    plugins: {
                        legend: {
                            display: false,
                        },
                        tooltip: {
                            enabled: this.tokenomicsData.chartOptions.tooltip, // Set to true to enable tooltips on hover
                        },
                        datalabels: {
                            display: false,
                        },
                    },
                    cutout: "32%",
                    responsive: true,
                    maintainAspectRatio: false,
                };

                new Chart(ctx, {
                    type: this.tokenomicsData.chartOptions.type,
                    data: data,
                    options: options,
                });
            }
        },
    },
    mounted() {
      this.chartCanvas();
    },
};
</script>
    
<style scoped></style>
    