<template>
  <section class="features-area" id="Features">
    <div class="container">
      <div class="title-wrap" style="text-align: center">
        <h6
          class="sub-title aos-init"
          data-aos="fade-up"
          data-aos-duration="700"
        >
          {{ featureData.secSubTitle }}
        </h6>
        <h2
          class="getItNow-title shape aos-init"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          {{ featureData.secTitle.txt1 }}
        </h2>
        <h2
          class="getItNow-title aos-init"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          {{ featureData.secTitle.txt2 }}
        </h2>
      </div>
      <!-- End Titles -->
      <div class="features-wrap">
        <div
          v-for="(list, index) in featureData.featureLists"
          :key="index"
          class="feature"
        >
          <div class="feature-img">
            <img
              v-if="list.img"
              :src="list.img"
              alt="Foundation"
            />
          </div>
          <div class="feature-contents">
            <h4
              v-if="list.title"
              v-html="list.title"
            ></h4>
            <p v-if="list.description">
              {{ list.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
  
  <script>
export default {
  name: "FeaturesSection",
  components: {},
  props: ["featureData"],
  data: () => {
    return {};
  },
};
</script>
  
<style scoped></style>
  